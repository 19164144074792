import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { TenantService } from '@cvs/services/tenant.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    constructor(
        private tenantService: TenantService,
        @Optional() private moduleConfig: OAuthModuleConfig
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.toLowerCase();
        if (this.checkUrl(url)) {
            const tenantName = this.tenantService.getTenantName();
            const modifiedReq = req.clone({
                setHeaders: {
                    'X-Tenant': tenantName
                }
            });
            return next.handle(modifiedReq);
        }
        return next.handle(req);
    }

    private checkUrl(url: string): boolean {
        if (this.moduleConfig?.resourceServer?.allowedUrls) {
            return !!this.moduleConfig.resourceServer.allowedUrls.find((u) =>
                url.toLowerCase().startsWith(u.toLowerCase())
            );
        }

        return true;
    }
}
